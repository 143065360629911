import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { WebService } from '../../../../../../app/scripts/services/webService';
import { LoadAdventuresService } from 'ng-app/src/app/services/load-adventures/load-adventures.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-adventure-carousel',
  templateUrl: '/ng-app/src/app/components/home/adventure-carousel/adventure-carousel.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/adventure-carousel/adventure-carousel.component.css']
})
export class AdventureCarouselComponent implements OnInit {
  destinationsList = [];
  destinations = [];

  slideConfig = {
    slidesToShow: 4,         // Show 3 slides at a time
    slidesToScroll: 1,       // Scroll through 1 slide at a time
    autoplay: false,          // Enable autoplay if needed
    autoplaySpeed: 2000,     // Set autoplay speed (optional)
    dots: false,              // Show navigation dots
    infinite: true,  
    centerMode: true,
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1 
        }
      },
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1 
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1 
        }
      },
      {
        breakpoint: 692,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1 
        }
      }
    ]
  };

  constructor(
    @Inject("$rootScope") public rootScope: any,
    @Inject(UIRouter) public router: UIRouter,
    private webService: WebService
  ) {}

  ngOnInit(): void {
    this.getDestinationCategories();
  }

  getDestinationCategories() {
    this.webService.getDestinationCategories().then(res => {
      this.destinationsList = res['data'];
      this.destinationsList.forEach(x => {
        x.destination_pages.forEach(country => {
          this.destinations.push({...country, activity:x});
        })
      })
      this.shuffle(this.destinations);
    })
  }

  shuffle(array) {
    let currentIndex = array.length;
    while (currentIndex != 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  }

  goToSelectedCategory(category: any, option: any) {
    let categoryName = '';
    let locationName = '';
    let route = '';
    let routeData: any = {};
    // this.loadAdventures.setDefaultFilter();
    // this.loadAdventures.searchData = [];
    if (category?.slug) {
      categoryName = category?.slug;
    } else {
      categoryName = category?.title.replaceAll(' ', '-').toLowerCase();
    }
    if (category?.destination_pages && option) {
      //Destination location page
      locationName = option.name.replaceAll(' ', '-').toLowerCase();
      route = 'destination-location';
      routeData['destinationCategory'] = categoryName;
      routeData['destinationLocation'] = locationName;
    } else if (category?.destination_pages) {
      //Destination activity page
      route = 'destination-categories';
      routeData['destinationCategory'] = categoryName;
    } else if (option) {
      //Adventure page with option
      route = 'filter';
      category?.title && (routeData['activities'] = category?.title);
      option?.id && (routeData['activity_id'] = option?.id);
      routeData['page'] = 1;
      routeData['keyword'] = "";
      routeData['lang'] = "";
      routeData['lat'] = "";
      routeData['limit'] = 2;
      routeData['location'] = "";
      routeData['max_price'] = "";
      routeData['min_price'] = "";
      routeData['maxdate'] = "";
      routeData['mindate'] = "";
      routeData['sort'] = "";
    } else {
      //Adventure page from navbar menu
      route = 'activity-categories';
      routeData.activityCategory = categoryName;
      routeData.page = 1;
    }
    routeData['page'] = 1;
    this.router.stateService.go(route, routeData, { reload: true });
  }
}
