import { Component, OnInit, Inject, Injectable} from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { CookieService } from 'ngx-cookie-service';
import { helper } from '../../../../../app/scripts/helper';
import { UserAdventureDetailService } from '../../../../../app/scripts/services/userAdventureDetailService';
import { SearchService } from '../../../../../app/scripts/services/searchService';
import { RootScopeService } from '../../services/root-scope/root-scope.service';
import { isArray } from 'angular';

@Injectable({
  providedIn: 'root'
})
export class LoadAdventuresService {

  defaultFilter: any = {
    abilities: [
      { name: 'Beginner', isChecked: false, id: 1 },
      { name: 'Intermediate', isChecked: false, id: 2 },
      { name: 'Expert', isChecked: false, id: 3 },
      { name: 'Legendary', isChecked: false, id: 4 }
    ],
    ability_id: [],
    activities: [],
    activity_id: [],
    back: 0,
    days: 0,
    featuredActivityId: '',
    keyword: '',
    lang: '',
    lat: '',
    limit: 20,
    location: '',
    max_price: '',
    min_price: '',
    maxdate: '',
    mindate: '',
    package_sports_id: '',
    page: 1,
    people: 0,
    sort: "",
    sortingKey: "",
    sortingReverse: false
  };
  selectedActivity: any;
  resultFound = 0;
  activityData: any;
  searchData: any = [];
  featuredActivity: any = {
    'ski': [1, 2, 3, 4],
    'kite': [5, 6, 37, 39, 38],
    'surf': [8, 7, 40, 41],
    'fish': [43, 10, 9, 42],
    'bike': [13, 14],
    'dive': [11, 12],
    'safari': [22, 20, 48]
  };
  noDataFoundAfterFilter: any = false;
  noDataFoundAfterSearch: any = false;
  localCurrencyCode: any;
  filtersApplied: any = [];
  appliedFilters: any = [];
  filter =  this.cloneObject(this.defaultFilter);
  is_filter_adventures = false;
  filterTagTemplates: {
    name: string,
    type: string,
    selector: string[],
    id?: number
  }[] = [
    { name: '', type: 'activity', selector: ['activity_id'], id: null },
    { name: '', type: 'price', selector: ['min_price', 'max_price'] },
    { name: '', type: 'days', selector: ['days'] },
    { name: '', type: 'people', selector: ['people'] },
    { name: '', type: 'ability', selector: ['ability_id'], id: null },
  ];

  constructor(
    @Inject(SearchService) private searchService: SearchService,
    @Inject(UserAdventureDetailService) private userAdventureDetailService: UserAdventureDetailService,
    @Inject(RootScopeService) public rootScopeService: RootScopeService,
    @Inject(CookieService) private cookies: CookieService,
    @Inject("$rootScope") private rootScopeJS: any,
    public router: UIRouter,
    public routerGlobals: UIRouterGlobals
  ) {}

  navigateWithFilterParams(page: string) {
    const routData = {};
    // add single filter params to url
    const keysOfAppliedFilters = Object.keys(this.filter)
      .filter((key) => this.filter[key] && !isArray(this.filter[key]) && this.filter[key] !=0)
    keysOfAppliedFilters.forEach(key => routData[key] = this.filter[key]);
    // add filter params from arrays to url
    const {activity_id, ability_id} = this.filter;
    activity_id.length && (routData['activity_id'] = activity_id.join());
    ability_id.length && (routData['ability_id'] = ability_id.join());
    this.router.stateService.go(page, routData, {notify: false, inherit: false});
  }

  parseFilterParamsFromUrl() {
    const filterParams = this.cloneObject(this.routerGlobals.params);
    Object.keys(filterParams).forEach(paramKey => {
      if (filterParams[paramKey] && (paramKey == 'activity_id'  || paramKey == 'ability_id')) {
        this.filter[paramKey] = !isArray(filterParams[paramKey]) ? filterParams[paramKey].split(',').map(id => Number(id)) : this.cloneObject(filterParams[paramKey]);
        const paramArray = this.filter[paramKey.slice(0, -4) + 'ies'];
        this.filter[paramKey].forEach(id => paramArray.find(param => param.id === id && (param.isChecked = true)));
      } else {
        if (paramKey === 'activities') {
          // Need to parse old url links
          this.filter['oldActivities'] = filterParams[paramKey];
        } else {
          filterParams[paramKey] && (this.filter[paramKey] = filterParams[paramKey]);
        }
      }
    });
    this.filter.page = Number(filterParams.page);
  }

  async setDefaultFilter(pageName: string = null) {
    this.filtersApplied = []; 
    this.filter = this.cloneObject(this.defaultFilter);
    this.localCurrencyCode = this.rootScopeJS.localCurrencyCode || 'USD';
    try {await this.getActivities();} catch(e) {this.errorLoadingData()}
    this.parseFilterParamsFromUrl();
    switch (pageName) {
      case 'filter':
        this.applyFilters(this.filter.page);
        return;
      case 'activity-categories':
        this.routerGlobals.params.activityCategory && (this.selectedActivity = this.routerGlobals.params.activityCategory);
        const categoriesArray = helper.FEATURED_ACTIVITY.length ? helper.FEATURED_ACTIVITY : helper.FEATURED_ACTIVITY_LOCAL;
        this.activityData = categoriesArray.find((obj) => {
          return obj.slug.toLowerCase() === this.selectedActivity.toLowerCase();
        });
        this.rootScopeService.updateMetaTitle(this.activityData.title);
        this.filter.package_sports_id = this.activityData.id;
        this.applyFilters(this.filter.page);
        return;
    }
  }

  applyFilters(page: number = 1) {
    this.createTags();
    this.filter.page = page ? page : 1;
    this.setListStyles();
    this.rootScopeJS.showLoaderRootScope();
    this.is_filter_adventures = true;
    this.filterAdventures().then((adv: any) => {
      adv ? this.setAdventureData(adv, false, false) : this.errorLoadingData();
    }, err => {
      this.errorLoadingData();
    });
  };

  filterAdventures() {
    return new Promise((resolve, reject) => {
      this.handleArrayFilterParams();
      this.searchService.filterAdventures(this.filter)
        .then((response) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  handleArrayFilterParams() {
    this.filter.activities.forEach(activity => {
      activity.isChecked && !this.filter.activity_id.includes(activity.id) && this.filter.activity_id.push(activity.id);
    });
    this.filter.abilities.forEach(ability => {
      ability.isChecked && !this.filter.ability_id.includes(ability.id) && this.filter.ability_id.push(ability.id);
    });
    // change $cookies on cookiesService
    this.cookies.set('activity_id', JSON.stringify(this.filter.activity_id));
    this.cookies.set('ability_id', JSON.stringify(this.filter.ability_id));
  }

  loadAdventure() {
    this.rootScopeJS.showLoaderRootScope();
    this.is_filter_adventures = false;
    this.searchService.searchAdventures(this.filter).then(adv => {
      adv ? this.setAdventureData(adv, false, false) : this.errorLoadingData();
    }, (err) => {
      this.errorLoadingData();
    });
  }

  setAdventureData(adv, loadMore: boolean = false, suggestedAdventures: boolean = false) {
    if (adv.data?.code == 400) { 
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = true;
      this.rootScopeJS.hideLoaderRootScope();
      return; 
    }
    let adventuresData = adv['data'].response;
    this.appliedFilters = this.filtersApplied.slice();
    this.resultFound = adventuresData.numFound;
    this.searchData = !loadMore ? adventuresData.docs : this.searchData.concat(adventuresData.docs);
    this.navigateWithFilterParams(this.selectedActivity ? 'activity-categories' : 'filter');
    if (this.searchData.length) {
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = false;
      this.getFeaturedAdventureData(this.searchData);
    } else if (suggestedAdventures) {
      this.noDataFoundAfterSearch = true;
      this.getAdventuresSuggestion(this.filter);
    } else {
      this.noDataFoundAfterSearch = this.noDataFoundAfterFilter = true;
      this.rootScopeJS.hideLoaderRootScope();
    }
  }

  getAdventuresSuggestion(filter) {
    this.searchService.getAdventureSuggestion(filter).then(res => {
      let adventuresSuggestedData = res['data'].response ? res['data'].response.docs : [];
      this.getFeaturedAdventureData(adventuresSuggestedData);
      this.searchData = adventuresSuggestedData.slice();
    }, err => {
      this.errorLoadingData();
    });
  }

  getFeaturedAdventureData(searchData) {
    searchData.forEach((adventure, key) => {
      this.getLocalCurrencyRate(searchData, adventure, key);
      this.checkExpiredSlot(adventure, key);
      this.getAdventureAvailableSeats(adventure);
    });
    this.rootScopeJS.hideLoaderRootScope();
  }

  getActivities() {
    return new Promise<void>((resolve, reject) => {
      this.filter.package_sports_id = this.routerGlobals.params.package_sports_id || '';
      this.searchService.getActivitiesList(this.filter.package_sports_id)
      .then((resp: any) => {
        this.filter.activities = resp.data;
        resolve();
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  getLocalCurrencyRate(searchData, adventure, key) {
    adventure.localCurrencyRate = 1;
    if (adventure.currency_code && adventure.currency_code[0] !== this.localCurrencyCode) {
      this.userAdventureDetailService.convertCurrency(1, adventure.currency_code[0], this.localCurrencyCode).then(res => {
        adventure.localCurrencyRate = res['data'].usd_rate;
        searchData.length === ++key && this.sortByPrice(this.filter.sort);
      });
    }
    adventure.convertedPrice = adventure.min_price * adventure.localCurrencyRate;
    adventure.packageCurrencyCode = this.localCurrencyCode;
    adventure.startDate = new Date(adventure.min * 1000);
    adventure.medias?.forEach(img => {
      if (img?.media_type === 0 && img?.is_main === 1) { // can be 0,1,2
        adventure.featuredImg = img.url ? img.url : 'images/img2.png';
      }
    });
  }

  checkExpiredSlot(adventure, key) {
    adventure.hasActiveSlots = false;
    adventure.availableSeats = adventure.activeSlots = 0;
    adventure.package_slots?.forEach((slot) => {
      let slotStartDate = new Date(slot.from_date);
      let currentDate = new Date();
      let diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
      if (diffDays < 0) { // if slot expired
        slot.expired = true;
      } else {
        slot.expired = false;
        adventure.hasActiveSlots = true;
        adventure.availableSeats += slot.available_seat;
        adventure.activeSlots += 1;
      }
    });
  }

  getAdventureAvailableSeats(adventure) {
    adventure.booking_type?.slice().shift() == 2 && (adventure.availableSeats = adventure.total_nightly_available_days[0]);
  }

  sortByPrice(sortby) {
    // handle price sorting in front end using orderby
    switch (sortby) {
      case 'min_price-asc':
        this.filter.sortingKey = 'convertedPrice';
        this.filter.sortingReverse = false;
        break;
      case 'min_price-desc':
        this.filter.sortingKey = 'convertedPrice';
        this.filter.sortingReverse = true;
        break;
      default :
        this.filter.sortingKey = '';
        this.filter.sortingReverse = false;
        break;
    }
  }

  createTags() {
    this.filterTagTemplates.forEach((tagTemplate, i) => {
      const appliedFilter = this.filtersApplied.find(appliedFilter => appliedFilter.type === tagTemplate.type);
      if (!appliedFilter) {
        this.createNewTag(tagTemplate)
      } else {
        this.changeExistingTag(tagTemplate);
      }
    });
  }

  createNewTag(tagTemplate) {
    const filterParam = this.filter[tagTemplate.selector[0]];
    if (
      (filterParam && !isArray(filterParam)) ||
      (tagTemplate.selector.length > 1 && (filterParam || this.filter[tagTemplate.selector[1]]))
    ) {
      this.setTagText(tagTemplate);
      this.filtersApplied.push(tagTemplate);
    } else if (filterParam && isArray(filterParam) && filterParam.length) {
      filterParam.forEach(id => {
        const newTag = this.cloneObject(tagTemplate);
        newTag.id = id;
        this.setTagText(newTag, id);
        this.filtersApplied.push(newTag);
      });
    }
  }

  changeExistingTag(tag) {
    if (this.filter[tag.selector[0]] === 0 || (this.filter[tag.selector[0]] === '0')) {
      //delete tag
      this.filtersApplied = this.filtersApplied.filter(filter => filter.type !== tag.type);
    } else {
      this.setTagText(tag);
    }
  }

  setTagText(tag, id = null) {
    let tagText = '';
    switch(tag.type) {
      case 'days':
        tagText = this.filter.days == 1 ? ' day' : ' days';
        tag.name = this.filter.days + tagText;
        break;
      case 'people':
        tagText = this.filter.people == 1 ? ' person' : ' people';
        tag.name = this.filter.people + tagText;
        break;
      case 'price':
        if (this.filter.max_price && !this.filter.min_price) {
          tag.name = '$1 - ' + this.filter.max_price;
        } else if (this.filter.max_price && this.filter.min_price) {
          tag.name = '$' + this.filter.min_price + ' - ' + this.filter.max_price;
        } else if (this.filter.min_price && !this.filter.max_price) {
          tag.name = 'Over $' + this.filter.min_price;
        }
        break;
      case 'ability':
        tag.name = this.filter.abilities.find(ability => ability.id === id)?.name;
        break;
      case 'activity':
        tag.name = this.filter.activities.find(activity => activity.id === id)?.category_name;
        break;
    }
  }

  removeRouteDash(route) {
    let correctString = '';
    [...route].forEach(letter => {
      correctString += letter == '-' ? ' ' : letter == ' ' ? '-' : letter.toLowerCase();
    });
    return correctString;
  }

  scrollToListTop(){
    const element = document.getElementById('activities-list');
    const headerOffset = 95;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  errorLoadingData() {
    this.rootScopeJS.hideLoaderRootScope();
    this.rootScopeService.showPopup('#serverErrModal');
  }

  cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  setListStyles() {
    const listWrapper = document.getElementById('filter-head');
    listWrapper?.classList.add('toggled'); // hide sidebar-filter
    listWrapper && (listWrapper.style.minHeight = 'unset');
  }
}
